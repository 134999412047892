.MuiContainer-root {
  padding-left: 0!important;
  padding-right: 0!important;
}

.mainContent {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  background-color: #f1f1f1;
}

.formContent {
  width: 60vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vw;
}

.linkStyle {
  color: #212121;
  text-decoration: none;
}

.introBlock {
  padding: 2em;
}

h1 {
  line-height: 1.3;
}

h4 {
  color: #f1f1f1;
  font-size: 1.5em!important;
  margin-top: 10px!important;
  margin-bottom: 10px!important;
}

body {
  overflow-y: overlay;
  min-height: 100vh;
}

@media screen and (orientation: portrait) {
  .animeLayout {
    background-image: url("./assets/img/base-img/sieve-anime-background-mobile.webp")!important;
  }
}

.animeLayout {
  background-image: url("./assets/img/base-img/sieve-anime-background.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.MuiCard-root {
    margin-left: 1em;
    margin-right: 1em;
}

.MuiCardHeader-title {
  color: #fff!important;
}

.MuiCardHeader-subheader {
  color: #fff!important;
}

.animeNav {
  background-color: #792c87!important;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  margin-top: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

